import React, { useState } from 'react';
import styled from 'styled-components';
// import logo from './logo.svg';
// import './App.css';
import Upload from './components/upload/upload';
// import InputUploadProgress from './components/upload/ui/input-upload-progress';
// import InputUploadProgressAnimated from './components/upload/ui/input-upload-progress-animated';
// import ListFiles from './components/upload/ui/list-files';
// import ListUploadedNames from './components/upload/ui/list-uploaded-files';
// import Config from './config/config.specific';
import Config from './config/config.general';
import Label from './components/upload/ui/label';
import SingleUploadProgress from './components/upload/ui/single-upload-progress';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  .upload-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 320px;
  }

  .input-upload {
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 20px;
  }

  .upload-bar {
    border: 1px solid black;
    width: 100%;
  }

  .preview-image {
    max-height: 300px;
  }

  .upload-url {
    margin-bottom: 20px;
  }

  .copy-url {
    border: 1px solid black;
    padding: 10px;
    cursor: pointer;

    &:disabled,
    &[disabled] {
      cursor: default;
      background: gainsboro;
    }
  }
`;

const sanitizeUploadUrl = url => {
  const imageFile = url.split('/upload/')[1];
  const fullUrl = Config.API_UPLOAD + '/' + imageFile;
  return fullUrl;
};

function App() {
  const [url, setUrl] = useState();
  const [progress, setProgress] = useState(null);
  const [copyLink, setCopyLink] = useState('Copier le lien');

  const onCompleteCallback = payload => {
    setUrl(sanitizeUploadUrl(payload[0]));
    setCopyLink('Copier le lien');
  };

  const onProgressCallback = value => setProgress(value);

  const copyToClipboard = () => {
    if (url !== undefined) {
      const copyText = document.getElementById('upload-url').textContent;
      navigator.clipboard.writeText(copyText);
      setCopyLink('Lien copié !');
    }
  };

  return (
    <AppWrapper className="App">
      <h1>Téléchargement d'image</h1>
      <Upload
        key={`upload`}
        onProgress={onProgressCallback}
        onComplete={onCompleteCallback}
        className={`upload-container`}
      >
        <Label key={`upload-label`} clickToUpload className={'input-upload'}>
          choisissez votre image
        </Label>
        {progress > 0 && (
          <SingleUploadProgress
            className={`upload-bar`}
            progressBarBackground={`royalblue`}
          />
        )}
        {progress === 100 && (
          <>
            <h2>Aperçu de l'image</h2>
            <img src={url} className={`preview-image`} alt="" />
            <h2>Url de l'image téléchargée :</h2>
            <div className={`upload-url`} id={`upload-url`}>
              {url !== undefined ? url : ''}
            </div>
            <div
              className={`copy-url`}
              disabled={url !== undefined ? false : true}
              onClick={() => copyToClipboard()}
            >
              {copyLink}
            </div>
          </>
        )}
      </Upload>
    </AppWrapper>
  );
}

export default App;
