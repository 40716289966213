import React, { useEffect, useState } from 'react';
import futch from '../../utils/web/requests/futch';
import counterIncrementor from '../../utils/web/counters/counter-incrementor';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import Config from '../../config/config.specific';
import Config from '../../config/config.general';

let uploadCounter = 0;

const UploadWrapper = styled.div`
  input[type='file'] {
    display: none;
  }

  label {
    margin: 0;
    cursor: pointer;
  }
`;

const Upload = props => {
  const uploadUrl = Config.API_UPLOAD;
  // const uploadUrl = process.env.CONFIG.API_UPLOAD;
  const [progress, setProgress] = useState(0);
  const [fileList, setFileList] = useState({ length: 0 });
  const [uploadedFiles, setUploadedFiles] = useState();
  uploadCounter = counterIncrementor(uploadCounter);

  const calculateProgress = e => {
    if (e.lengthComputable) {
      setProgress(Math.floor((e.loaded / e.total) * 100));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    //reset upload progress, this only works for single file input
    setProgress(0);

    const files = e.target.files;

    if (files.length > 0) {
      //check if files where selected
      setFileList(files);
      const url = uploadUrl;
      const formData = new FormData();

      for (const file of files) {
        formData.append('file-upload', file);
      }

      const options = {
        method: 'post',
        body: formData,
      };

      const upload = await futch(url, options, calculateProgress);
      const result = await JSON.parse(upload);
      setUploadedFiles(result.uploadedFiles);

      if (props.onComplete !== undefined) {
        props.onComplete(result.uploadedFiles);
      }
    }
  };

  const onProgress = value => {
    if (props.onProgress !== undefined) {
      props.onProgress(value);
    }
  };

  useEffect(() => {
    onProgress(progress);
    // eslint-disable-next-line
  }, [progress]);

  return (
    <UploadWrapper className={props.className} key={`upload--${uploadCounter}`}>
      <input
        id={`file-upload--${uploadCounter}`}
        type="file"
        name="file-upload"
        onChange={e => handleSubmit(e)}
        multiple={props.multiple}
      />
      <label htmlFor={`file-upload--${uploadCounter}`}>
        {props.children && props.children.length > 1 //if more than 1 child in Upload, else...
          ? props.children.map(
              child =>
                child.props !== undefined &&
                child.props.hasOwnProperty('clickToUpload') === true &&
                React.cloneElement(child, {
                  progress,
                  fileList,
                  uploadedFiles,
                }),
            )
          : props.children &&
            props.children.props.hasOwnProperty('clickToUpload') === true &&
            React.cloneElement(props.children, {
              progress,
              fileList,
              uploadedFiles,
            })}
      </label>
      {props.children && props.children.length > 1 //if more than 1 child in Upload, else...
        ? props.children.map(
            child =>
              child.props !== undefined &&
              child.props.hasOwnProperty('clickToUpload') === false &&
              React.cloneElement(child, { progress, fileList, uploadedFiles }),
          )
        : props.children &&
          props.children.props.hasOwnProperty('clickToUpload') === false &&
          React.cloneElement(props.children, {
            progress,
            fileList,
            uploadedFiles,
          })}
    </UploadWrapper>
  );
};

Upload.defaultProps = {
  multiple: false,
  clickToUpload: false,
  onComplete: undefined,
};

Upload.propTypes = {
  multiple: PropTypes.bool, //upload one or several files
  clickToUpload: PropTypes.bool, //if true, clicking on it will open the file upload window
  onComplete: PropTypes.func, //finished download callback
};

export default Upload;
