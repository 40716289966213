import React from 'react';
import styled from 'styled-components';

const LabelWrapper = styled.div``;

const Label = props => {
  const { key, children } = props;
  return <LabelWrapper key={key} {...props}>{children}</LabelWrapper>;
};

export default Label;
