import React from 'react';
import styled from 'styled-components';

const SingleUploadProgressWrapper = styled.div`
  height: 20px;
  width: 100%;

  .progressBar {
    height: 100%;
    background: ${props => props.progressBarBackground};
  }
`;

const SingleUploadProgress = props => {
  return (
    <SingleUploadProgressWrapper key={`single-upload-progress`} {...props}>
      <div
        className="progressBar"
        style={{ width: `${props.progress}%` }}
      ></div>
    </SingleUploadProgressWrapper>
  );
};

SingleUploadProgress.defaultProps = {
progressBarBackground: 'red'
}

export default SingleUploadProgress;
